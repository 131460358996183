import { useState, useEffect } from "react";
import Api from "../../Auth/Api";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement } from 'chart.js';
import { Pie, Bar } from 'react-chartjs-2';
import * as XLSX from 'xlsx';

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement);

function RelatorioFeedback() {
    const token = JSON.parse(localStorage.getItem("user_token"));
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [viewType, setViewType] = useState('pizza'); // pizza, table, bar

    const getRandomColors = (count) => {
        const colors = [];
        for (let i = 0; i < count; i++) {
            const color = `hsl(${Math.random() * 360}, 70%, 50%)`;
            colors.push(color);
        }
        return colors;
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await Api.get('api/estatisticas-pesquisa-satisfacao', {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setData(response.data);
                setLoading(false);
            } catch (error) {
                console.error(error);
                setLoading(false);
            }
        };
        fetchData();
    }, [token]);

    const createChartData = (question, responses) => {
        const labels = Object.keys(responses);
        const values = Object.values(responses);
        const backgroundColor = getRandomColors(labels.length);

        return {
            labels,
            datasets: [{
                data: values,
                backgroundColor,
                borderWidth: 1
            }]
        };
    };

    const createBarChartData = (question, responses) => {
        const labels = Object.keys(responses);
        const values = Object.values(responses);
        const backgroundColor = getRandomColors(1)[0];

        return {
            labels,
            datasets: [{
                label: 'Respostas',
                data: values,
                backgroundColor,
                borderWidth: 1
            }]
        };
    };

    const renderVisualization = (question, responses, index) => {
        switch (viewType) {
            case 'pizza':
                return (
                    <div style={{ height: '300px' }}>
                        <Pie
                            data={createChartData(question, responses)}
                            options={{
                                responsive: true,
                                maintainAspectRatio: false,
                                plugins: {
                                    legend: { position: 'bottom' }
                                }
                            }}
                        />
                    </div>
                );
            case 'table':
                return (
                    <div className="table-responsive">
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th>Resposta</th>
                                    <th>Quantidade</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.entries(responses).map(([response, count], i) => (
                                    <tr key={i}>
                                        <td>{response}</td>
                                        <td>{count}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                );
            case 'bar':
                return (
                    <div style={{ height: '300px' }}>
                        <Bar
                            data={createBarChartData(question, responses)}
                            options={{
                                responsive: true,
                                maintainAspectRatio: false,
                                plugins: {
                                    legend: { display: false }
                                },
                                scales: {
                                    y: {
                                        beginAtZero: true,
                                        ticks: {
                                            stepSize: 1
                                        }
                                    }
                                }
                            }}
                        />
                    </div>
                );
            default:
                return null;
        }
    };

    const handleExportExcel = () => {
        if (!data) return;

        const dataForExcel = Object.entries(data).map(([question, responses]) => {
            const row = {
                'Pergunta': question,
            };
            
            // Adiciona todas as respostas como colunas
            Object.entries(responses).forEach(([response, count]) => {
                row[response] = count;
            });
            
            return row;
        });

        // Cria uma nova planilha
        const worksheet = XLSX.utils.json_to_sheet(dataForExcel);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Feedback");

        // Gera o arquivo Excel e faz o download
        XLSX.writeFile(workbook, "relatorio_feedback.xlsx");
    };

    if (loading) {
        return (
            <div className="d-flex justify-content-center">
                <div className="loader"></div>
            </div>
        );
    }

    return (
        <div className="fade-in w-100 mt-3" style={{ marginBottom: '100px' }}>
            <div className="d-flex justify-content-between align-items-center">
                <div>
                    <span className="text-primary fs-1">Estatísticas de Feedback</span>
                    <p className="fs-6">Visualize as estatísticas das pesquisas de satisfação dos usuários.</p>
                </div>
                <div className="d-flex gap-2">
                    <div className="btn-group">
                        <button 
                            className={`btn ${viewType === 'pizza' ? 'btn-primary' : 'btn-outline-primary'}`}
                            onClick={() => setViewType('pizza')}
                        >
                            <i className="bi bi-pie-chart"></i> Pizza
                        </button>
                        <button 
                            className={`btn ${viewType === 'table' ? 'btn-primary' : 'btn-outline-primary'}`}
                            onClick={() => setViewType('table')}
                        >
                            <i className="bi bi-table"></i> Tabela
                        </button>
                        <button 
                            className={`btn ${viewType === 'bar' ? 'btn-primary' : 'btn-outline-primary'}`}
                            onClick={() => setViewType('bar')}
                        >
                            <i className="bi bi-bar-chart"></i> Colunas
                        </button>
                    </div>
                    <button 
                        className="btn btn-success"
                        onClick={handleExportExcel}
                        disabled={!data}
                    >
                        <i className="bi bi-file-earmark-spreadsheet"></i> Download
                    </button>
                </div>
            </div>

            <div className="row g-4">
                {data && Object.entries(data).map(([question, responses], index) => (
                    <div key={index} className="col-md-6">
                        <div className="card h-100">
                            <div className="card-body">
                                <h5 className="card-title mb-4">{question}</h5>
                                {renderVisualization(question, responses, index)}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default RelatorioFeedback;